<template>
  <v-app-bar
    fixed
    v-scroll="onScroll"
    height="100"
    style="z-index: 99999"
    :class="isScrolling ? 'scrolled-lightappbar' : 'unscrolled-lightappbar'"
    v-if="$vuetify.breakpoint.mdAndUp"
    color="#FFFFFF"
    flat
  >
    <v-img
      class="appbar-logo"
      contain
      width="170"
      height="65"
      @click.prevent="$router.push('/')"
      src="/static/logo_stc.png"
      style="cursor: pointer"
    ></v-img>
    <v-toolbar-items>
      <v-btn
        href="/"
        text
        :class="'/' === $route.path ? 'activeNavClass' : 'toolbar-btn-light'"
        @click.prevent="$router.push('/')"
        >{{ texts.menu.home }}</v-btn
      >
      <v-btn
        href="/transportes"
        text
        :class="
          '/transportes' === $route.path
            ? 'activeNavClass'
            : 'toolbar-btn-light'
        "
        @click.prevent="$router.push('/transportes')"
        >{{ texts.menu.who }}</v-btn
      >
      <v-btn
        href="/reciclaje"
        text
        :class="
          '/reciclaje' === $route.path ? 'activeNavClass' : 'toolbar-btn-light'
        "
        @click.prevent="$router.push('/reciclaje')"
        >{{ texts.menu.manuServ }}</v-btn
      >
      <v-btn
        href="/confinamiento"
        text
        :class="
          '/confinamiento' === $route.path
            ? 'activeNavClass'
            : 'toolbar-btn-light'
        "
        @click.prevent="$router.push('/confinamiento')"
        >{{ texts.menu.comServ }}</v-btn
      >
      <v-btn
        href="/faq"
        text
        :class="'/faq' === $route.path ? 'activeNavClass' : 'toolbar-btn-light'"
        @click.prevent="$router.push('/faq')"
        >{{ texts.menu.faq }}</v-btn
      >
      <v-btn
        href="/contact"
        text
        :class="
          '/contact' === $route.path ? 'activeNavClass' : 'toolbar-btn-light'
        "
        @click.prevent="$router.push('/contact')"
        >{{ texts.menu.contact }}</v-btn
      >
    </v-toolbar-items>
  </v-app-bar>
  <!--<div v-else>
    <v-app-bar-nav-icon
      :color="showOptions ? 'black' : 'white'"
      style="position: absolute; z-index:50"
      @click="burgerClick"
    ></v-app-bar-nav-icon>
    <v-card v-if="showOptions" class="burgerMenu">
      <v-layout column>
        <v-btn href="#inicio" text @click.prevent="$vuetify.goTo('#inicio')">Inicio</v-btn>
        <v-btn href="#learnmore" text @click.prevent="$vuetify.goTo('#learnmore')">¿Cómo comenzar?</v-btn>
        <v-btn href="#influencers" text @click.prevent="$vuetify.goTo('#influencers')">Influencers</v-btn>
        <v-btn href="#try-it" text @click.prevent="$vuetify.goTo('#ourplans')">Descarga la app</v-btn>
      </v-layout>
    </v-card>
  </div>-->
  <v-app-bar
    v-else
    v-scroll="onScroll"
    height="100"
    style="z-index: 99999"
    :class="isScrolling ? 'scrolled-lightappbar' : 'unscrolled-lightappbar'"
    color="#FFFFFF"
    flat
  >
    <v-layout row wrap justify-center align-center>
      <v-flex xs3>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn style="z-index: 999" icon v-on="on">
              <v-icon color="black">mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card color="#772336" dark style="width: 100vh">
            <v-list color="transparent">
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/')"
                  v-text="texts.menu.home"
                  :style="'cursor: pointer; font-weight: bold; color:white; text-transform: capitilize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/transportes')"
                  v-text="texts.menu.who"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/reciclaje')"
                  v-text="texts.menu.manuServ"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/confinamiento')"
                  v-text="texts.menu.comServ"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/faq')"
                  v-text="texts.menu.contact"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/contact')"
                  v-text="texts.menu.contact"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
      <v-flex xs6>
        <v-img
          style="max-width: 80%"
          src="/static/logo_stc.png"
          @click.prevent="$router.push('/')"
        ></v-img>
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from "vuex";
import page_texts from "../../views/guests/guestTexts";

export default {
  data: () => ({
    isScrolling: false,
    showOptions: false,
  }),
  computed: {
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
    texts() {
      // return the texts JSON based on the language chosen
      // if more languages are added, convert into a switch with param (this.language)
      return this.language == 0 ? page_texts.spanish : page_texts.english;
    },
  },
  methods: {
    offsetTop() {
      return this.$store.state.offsetTop;
    },
    toggle_language(value) {
      this.$store.commit("toggle_language", value);
    },
    ...mapMutations(["toggleDrawer"]),
    onScroll() {
      this.isScrolling =
        (window.pageYOffset || document.documentElement.scrollTop || 0) > 600;
    },
    burgerClick() {
      this.showOptions = !this.showOptions;
    },
  },
};
</script>
<style>
.toolbar-btn-light {
  height: 75px !important;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6vh !important;
  color: #772336 !important;
}

.activeNavClass {
  height: 75px !important;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6vh !important;
  color: #772336 !important;
  border-bottom: 2px solid #772336;
}

.v-app-bar.v-app-bar--fixed {
  top: 0 !important;
}
.appbar-logo {
  margin-left: 10vw !important;
  max-width: 150px;
  margin-right: 10vw !important;
}
.scrolled-lightappbar {
  background-color: #ffffff !important;
}
.unscrolled-lightappbar {
  background-color: #ffffff !important;
}
</style>
